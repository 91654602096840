import { BackendQueryKeys, useTypeSafeQuery } from '@oresundsbron/api';
import { tapO } from '@oresundsbron/utilities';
import { pipe } from 'fp-ts/lib/function';
import { fromNullable, fromPredicate, match } from 'fp-ts/lib/Option';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../stores/auth';

export function useIsAuthenticated() {
  const [state, setState] = useState({ loading: true, authenticated: false });
  const { refetch, data } = useTypeSafeQuery(
    BackendQueryKeys.CustomerInformation(),
    ({ Customer }) => Customer.get,
    { enabled: false }
  );

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    pipe(
      isAuthenticated,
      fromPredicate((x) => !!x),
      tapO(() => refetch()),
      match(
        () => {
          setState({ loading: false, authenticated: false });
        },
        () => {}
      )
    );
  }, [isAuthenticated, refetch]);

  useMemo(
    () =>
      pipe(
        data,
        fromNullable,
        tapO(() => {
          setState({ authenticated: true, loading: false });
        })
      ),
    [data]
  );

  return state;
}
