import { FC } from 'react';
import Image from 'next/image';

type LogoProps = {
  logoSrc: string;
  width?: number;
  height?: number;
};

export const Logo: FC<LogoProps> = ({ logoSrc, width = 226, height = 44 }) => (
  <Image
    alt=""
    src={logoSrc}
    sizes=""
    height={height}
    width={width}
    priority
    unoptimized
  />
);
