import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { Logo } from '~/components/Logo';
import { links, getLink } from '~/lib/payLinks';
import { useRouter } from 'next/router';

export const Footer: React.FC = () => {
  const { t } = useTranslation(['common'], { nsMode: 'fallback' });
  const router = useRouter();
  const locale = (router.locale || 'sv') as keyof typeof links;

  return (
    <footer className="xxl:container bg-[#2c3437] px-4 py-8 sm:px-16 sm:py-16">
      <div className="flex flex-col justify-between gap-12 sm:flex-row">
        <div className="flex flex-col gap-2">
          <h5 className="mb-2 text-lg text-[#666666]">
            {t('oresundpay.footer.heading1')}
          </h5>
          <a
            className="text-sm text-slate-100"
            href={getLink(locale, 'guides')}
          >
            {t('oresundpay.footer.guides')}
          </a>
          <a
            className="text-sm text-slate-100"
            href={getLink(locale, 'licensPlate')}
          >
            {t('oresundpay.footer.numberPlate')}
          </a>
          <a
            className="text-sm text-slate-100"
            href={getLink(locale, 'payWithBizz')}
          >
            {t('oresundpay.footer.bizz')}
          </a>
          <a
            className="text-sm text-slate-100"
            href={getLink(locale, 'termsAndConditions')}
          >
            {t('oresundpay.footer.terms')}
          </a>
          <a className="text-sm text-slate-100" href={getLink(locale, 'faq')}>
            {t('oresundpay.footer.faq')}
          </a>
          <div className="pt-6">
            <Image
              src="/assets/ISO_9001.png"
              alt="ISO"
              width={80}
              height={80}
              unoptimized
            />
          </div>
        </div>
        <div className="flex flex-col gap-2 ">
          <h5 className="mb-2 text-lg text-[#666666]">
            {t('oresundpay.footer.heading2')}
          </h5>
          <a
            className="text-sm text-slate-100"
            href={getLink(locale, 'orderContract')}
          >
            {t('oresundpay.footer.orderBroPass')}
          </a>
          <a
            className="text-sm text-slate-100"
            href={getLink(locale, 'privacyPolicy')}
          >
            {t('oresundpay.footer.privacyPolicy')}
          </a>
          <a
            className="text-sm text-slate-100"
            href={getLink(locale, 'cookiePolicy')}
          >
            {t('oresundpay.footer.qualityPolicy')}
          </a>
          <a
            className="text-sm text-slate-100"
            href={getLink(locale, 'customerService')}
          >
            {t('oresundpay.footer.customerService')}
          </a>
          <a
            className="text-sm text-slate-100"
            href={getLink(locale, 'whistleBlower')}
          >
            {t('oresundpay.footer.whistleBlower')}
          </a>
        </div>
        <div className="flex flex-col ">
          <div className="my-5 ">
            <Logo
              width={150}
              height={36}
              logoSrc="https://images.ctfassets.net/nvd3zqha2yv3/GmG3kLO0P0eQzGxmpiNT3/baa1da78383fdb1a77b9746a2959ac09/oresundpay_negative.svg?fm="
            />
          </div>
          <h5 className="mb-2 text-lg text-[#666666]">
            {t('oresundpay.footer.heading3')}
          </h5>
          <span className="text-sm text-slate-100">
            {t('oresundpay.footer.address.info')}
          </span>
          <span className="text-sm text-slate-100">
            {t('oresundpay.footer.address1')}
          </span>
          <span className="text-sm text-slate-100">
            {t('oresundpay.footer.address2')}
          </span>
          <span className="text-sm text-slate-100">
            {t('oresundpay.footer.address3')}
          </span>
          <span className="mt-2 text-sm text-slate-100">v2.10.3</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
