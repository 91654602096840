import { useState, useEffect } from 'react';

export const mobileWithInPixels = '768';

const debounce = (func: Function, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const useIsMobile = () => {
  const [isMobile, setMobile] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        if (window.matchMedia(`(max-width: ${mobileWithInPixels}px)`).matches) {
          setMobile(true);
        } else {
          setMobile(false);
        }
      };

      const debouncedHandleResize = debounce(handleResize, 300);

      handleResize();

      window.addEventListener('resize', debouncedHandleResize);
      return () => {
        window.removeEventListener('resize', debouncedHandleResize);
      };
    }
  }, []);

  return isMobile;
};
