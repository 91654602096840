import { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import { ArrowUp } from '@oresundsbron/icons';
import SV from '../../public/icons/flag_sv.svg';
import EN from '../../public/icons/flag_en.svg';
import NO from '../../public/icons/flag_no.svg';
import DA from '../../public/icons/flag_da.svg';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { links } from '~/lib/payLinks';
import { useIsMobile } from '~/hooks/useIsMobile';

interface LanguagePickerProps {
  usedForMobile?: boolean;
  openLanguageSelectorProp?: boolean;
}

export const LanguagePicker = ({
  usedForMobile = false,
  openLanguageSelectorProp,
}: LanguagePickerProps) => {
  const { locale: routerLocale, asPath, push } = useRouter();
  const locale = (routerLocale || 'en') as keyof typeof links;

  const { t } = useTranslation(['common'], {
    nsMode: 'fallback',
  });

  const menuItems = [
    {
      country: t('oresundpay.header.languages.swedish'),
      locale: 'sv',
      icon: SV,
    },
    {
      country: t('oresundpay.header.languages.english'),
      locale: 'en',
      icon: EN,
    },
    // Save this code for later, need norweigan translations
    // {
    //   country: t('oresundpay.header.languages.norwegian'),
    //   locale: 'no',
    //   icon: NO,
    // },
    {
      country: t('oresundpay.header.languages.danish'),
      locale: 'da',
      icon: DA,
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        languageSelectorRef.current &&
        !languageSelectorRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).classList.contains(
          'language-selector-button'
        )
      ) {
        console.log('I are here');
        closeLanguageSelector();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [openLanguageSelector, setOpenLanguageSelector] = useState(
    openLanguageSelectorProp
  );
  const [selectedLocale, setSelectedLocale] = useState<string>(
    locale as string
  );

  const languageSelectorRef = useRef<HTMLDivElement>(null);

  const isMobile = useIsMobile();

  if ((!usedForMobile && isMobile) || (usedForMobile && !isMobile))
    return <></>;

  const handleLanguageChange = (locale: string) => {
    setSelectedLocale(locale);
    const currentPath = asPath;
    push(currentPath, currentPath, { locale });
  };

  const closeLanguageSelector = () => {
    setOpenLanguageSelector(false);
  };

  const mobileDropdownClasses =
    'right-0 transform gap-4 px-16 py-6 transition-transform duration-700 ease-in-out';
  const desktopDropdownClasses =
    'right-12 min-w-[100px] items-center gap-1 rounded-sm';

  return (
    <>
      <div className={`${isMobile ? 'relative' : 'flex items-center'}`}>
        <div className="flex items-center justify-center">
          <button
            className="language-selector-button flex items-center gap-2"
            onClick={() => setOpenLanguageSelector(!openLanguageSelector)}
          >
            <Image
              src={
                menuItems.find((item) => item.locale === selectedLocale)?.icon
              }
              alt={selectedLocale}
              width={20}
              height={20}
              unoptimized
              className="language-selector-button"
            />
            <ArrowUp
              width={10}
              className="language-selector-button rotate-180"
            />
          </button>
        </div>

        {openLanguageSelector && (
          <div
            ref={languageSelectorRef}
            className={`absolute top-full z-50 flex flex-col bg-white p-3 shadow-md ${
              isMobile ? mobileDropdownClasses : desktopDropdownClasses
            }`}
          >
            {menuItems.map((item, index) => (
              <button
                className="w-full rounded-md px-4 py-2 hover:bg-slate-50"
                onClick={() => {
                  handleLanguageChange(item.locale);
                  closeLanguageSelector();
                }}
                key={index}
              >
                <div className="flex w-full justify-start gap-2">
                  <Image
                    src={item.icon}
                    alt={item.country}
                    width={20}
                    height={20}
                    unoptimized
                  />
                  <span>{item.country}</span>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
