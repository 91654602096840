import { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import Hamburger from '../../public/icons/hamburger.svg';
import Person from '../../public/icons/person.svg';
import Close from '../../public/icons/close.svg';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Logo } from '~/components/Logo';
import Link from 'next/link';
import { links, getLink, baseURL } from '~/lib/payLinks';
import { LanguagePicker } from '~/components/LanguagePicker';
import { useIsAuthenticated } from '~/hooks/useIsAuthenticated';

export const Navbar = () => {
  const { authenticated, loading } = useIsAuthenticated();
  const { t } = useTranslation(['common'], {
    nsMode: 'fallback',
  });

  const router = useRouter();
  const locale = (router.locale || 'en') as keyof typeof links;
  const [openMenu, setOpenMenu] = useState(false);

  const mobileMenuRef = useRef<HTMLDivElement>(null);

  const closeMobileMenu = () => {
    setOpenMenu(false);
  };

  const handleDrawer = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).classList.contains('mobile-menu-button')
      ) {
        closeMobileMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header
      className={`xxl:container text-header-color relative flex h-24 flex-row items-center justify-between border-b border-gray-200 bg-white px-4 py-2 sm:px-10 lg:h-[100px]`}
    >
      <div className="w-1/2 cursor-pointer sm:w-auto">
        <Link href={`${baseURL}/${locale}`}>
          <div className="px-4">
            <Logo
              height={100}
              width={270}
              logoSrc="https://images.ctfassets.net/nvd3zqha2yv3/dzQVeIcNR3e3MRncenRq3/6bc0908562aba4139e32275dc110280e/oresundpay.svg?fm="
            />
          </div>
        </Link>
      </div>
      <div className="hidden gap-2 lg:flex">
        {!loading && !authenticated && (
          <Link
            href={getLink(locale, 'order')}
            className="rounded-md bg-[#0db14c] px-4 py-3 text-white active:bg-slate-50"
            onClick={() => setOpenMenu(false)}
          >
            {t('oresundpay.header.signUp')}
          </Link>
        )}
        <Link
          href={getLink(locale, 'place')}
          className="rounded-md px-4 py-3 hover:bg-slate-50 active:bg-slate-50"
        >
          {t('oresundpay.header.places')}
        </Link>
        <Link
          href={getLink(locale, 'help')}
          className="rounded-md px-4 py-3 hover:bg-slate-50 active:bg-slate-50"
        >
          {t('oresundpay.header.help')}
        </Link>
        <Link
          href={getLink(locale, 'about')}
          className="rounded-md px-4 py-3 hover:bg-slate-50 active:bg-slate-50"
        >
          {t('oresundpay.header.about')}
        </Link>{' '}
        <Link
          href={getLink(locale, 'kmToll')}
          className="rounded-md px-4 py-3 hover:bg-slate-50 active:bg-slate-50"
        >
          {t('oresundpay.header.kmToll')}
        </Link>
      </div>

      <div className="flex gap-4 sm:gap-3 lg:relative">
        <LanguagePicker />
        <button className="rounded-md px-2 py-2 hover:bg-slate-50 sm:px-6 sm:py-3">
          <div className="flex items-center gap-2">
            <div
              className="hidden lg:flex"
              onClick={() => router.push(`/${locale}`)}
            >
              {t('oresundpay.header.myAccount')}
            </div>
            <div>
              <Image
                width={24}
                height={24}
                priority
                src={Person}
                alt={t('oresundpay.header.myAccount')}
                unoptimized
              />
            </div>
          </div>
        </button>
        <button
          className="mobile-menu-button flex items-center justify-center rounded-md px-2 py-2  hover:bg-slate-50 sm:px-6 sm:py-3 lg:hidden"
          onClick={handleDrawer}
        >
          {openMenu ? (
            <Image
              className="mobile-menu-button"
              priority
              src={Close}
              alt={t('header.closeHamburgerMenu')}
              width={24}
              height={24}
              unoptimized
            />
          ) : (
            <Image
              className="mobile-menu-button"
              priority
              src={Hamburger}
              alt={t('header.openHamburgerMenu')}
              width={24}
              height={24}
              unoptimized
            />
          )}
        </button>
      </div>
      {openMenu && (
        <div
          ref={mobileMenuRef}
          className="absolute right-0 top-full z-50 flex transform flex-col items-center gap-4 bg-white p-3 px-16 py-6 shadow-md transition-transform duration-700 ease-in-out lg:hidden"
        >
          <LanguagePicker usedForMobile />

          {!loading && !authenticated && (
            <Link href={getLink(locale, 'order')}>
              <button
                onClick={closeMobileMenu}
                className="rounded-md bg-[#0db14c] px-6 py-3 text-white"
              >
                {t('oresundpay.header.signUp')}
              </button>
            </Link>
          )}
          <Link href={getLink(locale, 'place')}>
            <button
              onClick={closeMobileMenu}
              className="rounded-md px-6 py-3 hover:bg-slate-50"
            >
              {t('oresundpay.header.places')}
            </button>
          </Link>
          <Link href={getLink(locale, 'help')}>
            <button
              onClick={closeMobileMenu}
              className="rounded-md px-6 py-3 hover:bg-slate-50"
            >
              {t('oresundpay.header.help')}
            </button>
          </Link>
          <Link href={getLink(locale, 'about')}>
            <button
              onClick={closeMobileMenu}
              className="rounded-md px-6 py-3 hover:bg-slate-50"
            >
              {t('oresundpay.header.about')}
            </button>
          </Link>
          <Link
            href={getLink(locale, 'kmToll')}
            className="rounded-md px-6 py-3 hover:bg-slate-50 active:bg-slate-50"
          >
            {t('oresundpay.header.kmToll')}
          </Link>
        </div>
      )}
    </header>
  );
};
